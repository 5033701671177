import { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import { BoxOptions, Main, Wrapper } from './styles'
import { InputRadio, InputMask } from '../../Components/index'

const initValues = { tpInput: 'bar', barCode: '', codRequisicao: '' }

export default function FormEdit({ onChange }) {
  const [values, setValues] = useState(initValues)

  function onChangeL(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    if (values.tpInput === 'input' && values.codRequisicao.length === 9) {
      onSubmit()
    }
  }, [values])

  function onSubmit(ev) {
    if (ev) ev.preventDefault()

    if (values.tpInput === 'bar') {
      onChange(values.barCode)
      setValues({ ...values, barCode: '', codRequisicao: '' })
    } else if (values.tpInput === 'input' && values.codRequisicao.length === 9) {
      var digito = values.codRequisicao.substring(values.codRequisicao.length - 1)
      if (isNaN(digito)) {
        let _codRequisicao = values.codRequisicao.substring(0, values.codRequisicao.length - 1)
        _codRequisicao += digito.charCodeAt(0) - 55
        onChange(`00${_codRequisicao}0`)
      } else {
        let _requisicao = values.codRequisicao.substring(0, values.codRequisicao.length - 1)
        let _serier = values.codRequisicao.substring(values.codRequisicao.length - 1, values.codRequisicao.length)
        let _codRequisicao = _requisicao + '0' + _serier
        onChange(`00${_codRequisicao}0`)
      }
      setValues({ ...values, barCode: '', codRequisicao: '' })
    }
  }

  return (
    <Wrapper>
      <BoxOptions>
        <InputRadio label="Cód. de barras" onChange={onChangeL} value="bar" name="tpInput" isChecked={values.tpInput === 'bar'} />
        <InputRadio label="Requisição" onChange={onChangeL} value="input" name="tpInput" isChecked={values.tpInput === 'input'} />
      </BoxOptions>
      {values.tpInput === 'bar' ? (
        <Main onSubmit={onSubmit}>
          <Input type="text" onChange={onChangeL} name="barCode" id="barCode" value={values.barCode} maxLength={13} />
        </Main>
      ) : (
        <Main onSubmit={onSubmit}>
          <InputMask mask="00.000000-*" value={values.codRequisicao} onChange={(ev) => setValues({ ...values, codRequisicao: ev.target.value.toUpperCase() })} name="codRequisicao" />
        </Main>
      )}
    </Wrapper>
  )
}
