//https://icons.getbootstrap.com/icons/bar-chart/

export const NavAdmin = [
  { icon: 'fa fa-list', label: 'Página Inicial', to: '/#/' },

  { icon: 'fa fa-wpforms', label: 'Planejamento', to: '/#/Planning' },
  {
    icon: 'fa fa-flask',
    label: 'Fórmulas',
    content: [
      { label: 'Acompanhamento', to: '/#/Formulas/Query' },
      { label: 'Registro de etapas', to: '/#/Formulas/Step' },
      { label: 'Correção', to: '/#/Formulas/Conference' },
      { label: 'Ficha', to: '/#/Formulas/Datasheet' },
    ],
  },

  {
    icon: 'fa fa-truck',
    label: 'Entregas',
    content: [
      { label: 'Painel', to: '/#/Delivery/Panel' },
      { label: 'Divergências', to: '/#/Delivery/Reports/Divergences' },
      { label: 'Pendências', to: '/#/Delivery/Reports/Pending' },
    ],
  },
  {
    icon: 'bi bi-bar-chart',
    label: 'Indicadores',
    content: [
      { label: 'Gerenciais', to: '/#/Dashboard' },
      { label: 'Financeiros', to: '/#/Financial/Manager' },
      { label: 'Loja', to: '/#/Financial/Branch' },
      { label: 'Definição de Metas', to: '/#/Financial/Conf' },
    ],
  },

  { icon: 'fa fa-gear', label: 'Configurações', to: '/#/Settings' },
]

export const NavColaborador = [
  { icon: 'fa fa-list', label: 'Home', to: '/#/' },
  { icon: 'bi bi-bar-chart', label: 'Minhas Metas', to: '/#/Financial/Seller' },
  { icon: 'bi bi-bar-chart', label: 'Metas da loja', to: '/#/Financial/Branch' },
  {
    icon: 'fa fa-flask',
    label: 'Fórmulas',
    content: [
      { label: 'Acompanhamento', to: '/#/Formulas/Query' },
      { label: 'Registro de etapas', to: '/#/Formulas/Step' },
    ],
  },
]
