import React, { useRef } from 'react'
import { IMaskInput } from 'react-imask'

function InputComponent({ value, onChange, name, mask }) {
  const ref = useRef(null)
  const inputRef = useRef(null)

  const onInput = (value, mask) => {
    if (onChange) {
      onChange({ target: { value, name } })
    }
  }

  return (
    <IMaskInput
      value={value}
      className="form-control"
      mask={mask}
      radix="."
      unmask={true} // true|false|'typed'
      lazy={false}
      ref={ref}
      inputRef={inputRef}
      onAccept={onInput}
    />
  )
}

export default InputComponent
