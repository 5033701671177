import React from 'react'
import MetisMenu from 'react-metismenu'
import { NavAdmin, NavColaborador } from './NavItems'
import { getUser } from '../../Reducers/storageApp'

export default function AppNav() {
  const { perfil } = getUser()
  let menuRender
  if (perfil === 'A') {
    menuRender = (
      <>
        <MetisMenu content={NavAdmin} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="fa fa-angle-down" />
      </>
    )
  } else {
    menuRender = (
      <>
        <MetisMenu content={NavColaborador} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="fa fa-angle-down" />
      </>
    )
  }

  return <>{menuRender}</>
}
